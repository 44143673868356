<template>
  <div class="container h-100">
    <div class="row h-100 justify-content-center align-items-center">
      <div class="Login col-12 col-md-8 col-lg-6">
        <div class="Login__logo text-center">
          <img class="img-fluid" :src="logo" />
          <img class="img-fluid" :src="logoCEasy" />
        </div>
        <div class="Login__form text-center pt-4">
          <form autocomplete="off" novalidate @submit.prevent="resetPasswd()">
            <div class="text-left mb-2">
              {{ $t('LOGIN_RESET_TITLE') }}
            </div>

            <div class="form-group">
              <div class="input-group mb-2">
                <input
                  type="text"
                  class="form-control"
                  :class="{ 'is-invalid': errors.email }"
                  :placeholder="$t('LOGIN_RESET_EMAIL')"
                  v-model="email"
                />
                <div class="input-group-append">
                  <div class="input-group-text" :class="{ 'is-invalid': errors.email }">
                    <Email />
                  </div>
                </div>
                <div class="invalid-feedback">
                  {{ $t(errors.email) }}
                </div>
              </div>
            </div>
            <!-- Submit Button -->
            <div class="row">
              <div class="col-12 col-md-6 order-md-2 text-right mb-2">
                <button type="submit" :disabled="isAuthFetching" class="Login__submit-btn btn btn-primary">
                  {{ $t('LOGIN_RESET_SUBMIT') }}
                </button>
              </div>
              <div class="col-12 col-md-6 order-md-1 text-left">
                <div class="Login__forgot-link">
                  <router-link :to="{ name: 'login' }"> <ChevronLeft /> {{ $t('LOGIN_RESET_BACK') }}</router-link>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="row">
          <div class="col text-success text-center">{{ $t(success) }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Email from 'vue-material-design-icons/Email.vue'
import ChevronLeft from 'vue-material-design-icons/ChevronLeft.vue'
import validator from 'validator'
import logo from '@/assets/logo_sparco.svg'
import logoCEasy from '@/assets/logo_customeasy_oriz.png'

export default {
  name: 'resetpasswd',
  components: {
    Email,
    ChevronLeft
  },
  computed: {
    ...mapGetters(['isAuthFetching'])
  },
  data() {
    return {
      logo,
      logoCEasy,
      success: false,
      errors: {},
      email: ''
    }
  },
  methods: {
    resetPasswd() {
      this.errors = {}
      if (!this.email) {
        this.errors.email = 'LOGIN_RESET_ERROR_EMAIL_REQUIRED'
      } else if (!validator.isEmail(this.email)) {
        this.errors.email = 'LOGIN_RESET_ERROR_EMAIL_INVALID'
      }

      if (Object.keys(this.errors).length === 0) {
        this.$store.dispatch('AUTH_RESET_PASSWD', { email: this.email }).then(() => {
          this.email = ''
          this.errors = {}
          this.success = 'LOGIN_RESET_SUCCESS'
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.Login {
  background: $c-white;
  border-top: 5px solid $c-blue;
  padding: 30px;

  &__logo {
    img {
      width: 45%;
      &:first-child {
        margin-right: 5%;
      }
    }
  }

  &__form {
    .form-control {
      color: $c-grey3;
      font-size: 16px;
      background: $c-grey2;
      border: none;
      border-radius: 0;
      padding-right: 0;
      padding-top: 8px;
      &.is-invalid {
        border: 1px solid $c-error !important;
        border-right: none !important;
      }
    }
    .input-group-text {
      color: $c-grey3;
      font-size: 20px;
      background: $c-grey2;
      border: none;
      border-radius: 0;
      &.is-invalid {
        border: auto;
        border: 1px solid $c-error;
        border-left: none;
      }
    }
  }

  &__submit-btn {
    background: $c-blue;
    border: none;
    border-radius: 0;
    width: 100%;
  }

  &__forgot-link {
    font-size: 16px;
    padding-top: 6px;
    a {
      &:focus,
      &:visited,
      &:active {
        color: $c-grey3;
      }
      &:hover {
        color: $c-blue;
      }
      text-decoration: none;
      color: $c-grey3;
    }
  }
}
</style>
